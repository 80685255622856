<template>

  <div>
    <form>
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('MEMBER.FIRSTNAME')"
            label-for="firstname">
              <b-form-input autocomplete="off"
              id="firstname"
              v-model="form.firstname"
              placeholder=""
              />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label="$t('MEMBER.LASTNAME')"
            label-for="lastname">
              <b-form-input autocomplete="off"
              id="lastname"
              v-model="form.lastname"
              placeholder=""
              />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group
            :label="$t('MEMBER.COMPANY_NAME')"
            label-for="cname">
              <b-form-input autocomplete="off"
              id="cname"
              v-model="form.cname"
              placeholder=""
              />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">

          <b-form-group
            :label="$t('MEMBER.PERSONNR')"
            label-for="personnr">
              <b-form-input autocomplete="off"
              id="personnr"
              v-model="form.personnr"
              placeholder=""
              :state="validPersonnr"
              />
              <b-form-text>{{$t('MEMBER.PERSONNR_INFO')}}</b-form-text>
          </b-form-group>


        </b-col>

        <b-col cols="6">

          <b-form-group
            :label="$t('MEMBER.ORG_NUMBER')" label-for="org_number">
              <b-form-input autocomplete="off"
              id="org_number"
              v-model="form.org_number"
              placeholder=""
              :state="validOrgNumber"
              />
              <b-form-text>{{$t('MEMBER.ORG_NUMBER_INFO')}}</b-form-text>
          </b-form-group>


        </b-col>
      </b-row>

      <b-row>
        <b-col>

          <b-form-group
            :label="$t('MEMBER.EMAIL')"
            label-for="email">
              <b-form-input autocomplete="off"
              id="email"
              v-model="form.email"
              placeholder=""
              :state="validEmail"
              />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :label="$t('MEMBER.PHONE')"
            label-for="phone">
              <b-form-input autocomplete="off"
              id="phone"
              v-model="form.phone"
              placeholder=""
              :state="validPhone"
              />
          </b-form-group>

        </b-col>
      </b-row>

      <b-row>
        <b-col>

          <b-form-group
            :label="$t('MEMBER.ADDRESS')"
            label-for="address">
              <b-form-input autocomplete="off"
              id="address"
              v-model="form.address"
              :state="validAddress"
              placeholder=""
              />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="4">
          <b-form-group
            :label="$t('MEMBER.ZIPCODE')"
            label-for="zipcode">
              <b-form-input autocomplete="off"
              id="zipcode"
              v-model="form.zipcode"
              :state="validZipcode"
              placeholder=""
              />
          </b-form-group>

        </b-col>
        <b-col cols="12" lg="8">
          <b-form-group
            :label="$t('MEMBER.POSTAL')"
            label-for="post">
              <b-form-input autocomplete="off"
              id="post"
              v-model="form.post"
              :state="validPost"
              placeholder=""
              />
          </b-form-group>
        </b-col>
      </b-row>

      <RightSaveButton
        ref="save-button"
        :text="submit_text"
        @clicked="submit_clicked()"
      />

    </form>
  </div>

</template>


<style lang="scss" scoped>

.form-group {
  margin-bottom: 6px;
}

</style>

<script>

import { validate_email } from '@/core/services/email';
import validate_pers_number from '@/core/services/personnr';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {
  name: 'member-event-individual-editor',
  props: ['item', 'is_member', 'id'],
  emits: ['onCreate', 'onUpdate', 'change'],
  components: {
    RightSaveButton
  },
  mixins: [ toasts ],
  computed: {
    submit_text() {
      return (this.form.person_id || this.form.member_id ? this.$t('COMMON.UPDATE') : this.$t('COMMON.CREATE')) + ' ' + (this.is_member ? this.$t('MEMBER.MEMBER') : this.$t('MEMBER.PERSON'));
    },
    
    validName() {
      if (!this.form.name) { return null; }

      return this.form.name.length > 1;
    },
    validEmail() {
      if (!this.form.email) { return null; }

      return validate_email(this.form.email);
    },
    validPhone() {
      if (!this.form.phone) { return null; }

      return this.validate_phone(this.form.phone);
    },
    validOrgNumber() {
      if (!this.form.org_number) { return null; }

      return this.form.org_number.length === 10;
    },
    validPersonnr() {
      if (!this.form.personnr) { return null; }

      const formatted = validate_pers_number(this.form.personnr);

      return formatted.valid;
    },
    validZipcode() {

      if (!this.form.zipcode) { return null; }

      return !!this.form.zipcode && !isNaN(parseInt(this.form.zipcode)) && this.form.zipcode.length > 4;
    },
    validAddress() {
      if (!this.form.address) { return null; }

      return !!this.form && !!this.form.address && this.form.address.length > 3;
    },
    validPost() {
      if (!this.form.post) { return null; }

      return !!this.form && !!this.form.post && this.form.post.length > 1;
    },
    validAllAddress() {
      const v1 = this.notNullAndTrue(this.validZipcode);
      const v2 = this.notNullAndTrue(this.validAddress);
      const v3 = this.notNullAndTrue(this.validPost);

      return v1 && v2 && v3;
    },

    allIsValid() {
      const v1 = this.notNullAndTrue(this.validEmail);
      const v2 = this.notNullAndTrue(this.validPhone);
      //const v3 = this.notNullAndTrue(this.validPersonnr);
      const v4 = this.notNullAndTrue(this.validAllAddress);

      return v1 && v2 && v4;
    }
  },
  watch: {
    item(newValue) {

      if (newValue) {
        this.form = { ...newValue };
      }
      
    },
    'form.zipcode'() {
      this.$emit('change', this.form);
    },
    'form.address'() {
      this.$emit('change', this.form);
    },
    'form.phone'() {
      this.$emit('change', this.form);
    },
    'form.email'() {
      this.$emit('change', this.form);
    },
    'form.post'() {
      this.$emit('change', this.form);
    },
    'form.personnr'() {
      this.$emit('change', this.form);
    }
  },
  data() {
    return {
      creating: true,
      form: {}
    };
  },
  mounted() {
    if (this.item) {
      this.form = { ...this.item };
    }
  },
  methods: {
    submit_clicked() {
      if (this.is_member) {
        if (!this.form.member_id) {
          this.$emit('onCreate', this.form);
          this.creating = false;
        }
        else {
          this.$emit('onUpdate', this.form);
          this.creating = false;
        }
      }
      else {
        if (!this.form.person_id) {
          this.$emit('onCreate', this.form);
          this.creating = false;
        }
        else {
          this.$emit('onUpdate', this.form);
          this.creating = false;
        }
      }

      this.$refs['save-button'].stop();
    },
    notNullAndTrue(val) {
      if (val === null || val === undefined) { return false; }
      return val;
    },
    getForm() {
      return this.form;
    },
    validateAll() {
      const v1 = this.notNullAndTrue(this.validEmail);
      const v2 = this.notNullAndTrue(this.validPhone);
      //const v3 = this.notNullAndTrue(this.validPersonnr);
      const v4 = this.notNullAndTrue(this.validAllAddress);

      return v1 && v2 && v4;
    },

    validate_phone(phone) {
      if ((phone+'').length < 6) {
        return false;
      }

      var re = /^\+?\d*$/;
      return re.test(String(phone).toLowerCase());
    },

  }
};
</script>
