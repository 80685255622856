<template>
  <div>

    <b-modal ref="select-modal" hide-footer>
      <AttributeGroupSelectEditor
        :groups="groups"
        @selected="attribute_selected"
        @cancel="() => { this.$refs['select-modal'].hide(); }" />
    </b-modal>

    <b-row>
      <b-col cols="6" style="height: 110px;" class="pb-4">

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4 mt-8">

          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
            @click.prevent="open_select_editor"><i class="menu-icon flaticon2-plus"
              style="font-size: 1.0em;"></i>{{ $t('MEMBER_PROPERTY.ADD_GROUP') }}</a>

        </div>
      </b-col>
    </b-row>

    <!-- Table -->
    <div class="table-container">
      <b-table
        id="select-table"
        class="table-striped"
        :fields="fields"
        :items="selected_rows"
        head-variant="light"
        ref="select-table">
        <template #cell(action)="data">
          <div class="text-right">
            <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="delete_row_clicked(data.item.group_id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>

      <p class="ml-4" v-if="selected_rows.length === 0">{{ $t('ADVANCED_SEARCH.ATTRIBUTES.NONE_SELECTED') }}</p>

    </div>

  </div>
</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { mapGetters } from 'vuex';
import AttributeGroupSelectEditor from './AttributeGroupSelectEditor.vue';


export default {
  name: 'AttributeGroupSelectTable',
  emits: ['selected', 'select'],
  props: ['group_ids'],
  mixins: [ toasts ],
  components: {
    AttributeGroupSelectEditor
  },

  watch: {

    group_ids: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_group_ids = [ ...this.group_ids ];
      }
    },

    currentCompanyId() {
      this.load_items();
    }
  },

  computed: {
    ...mapGetters(['currentCompanyId']),

    is_mobile() {
      return is_mobile();
    },

    selected_rows() {
      const rows = [];

      for (const group_id of this.local_group_ids) {
        const group = this.groups.find(item => item.group_id === group_id);

        if (group) {
          rows.push(group);
        }
      }

      return rows;
    }
  },

  methods: {

    open_select_editor() {
      this.$refs['select-modal'].show();
    },

    attribute_selected(group_id) {

      this.$refs['select-modal'].hide();

      // check if it already exists

      const found = this.local_group_ids.find(item => item === group_id);

      if (found) {
        return;
      }

      this.local_group_ids.push(group_id);

      this.$emit('selected', this.local_group_ids);
    },

    async load_items() {
      try {
        const response = await axios.get(`/property_group?company_id=${this.currentCompanyId}`);
        this.groups = response.data;
      }
      catch (err) {
        console.error('error loading attributes', err);
      }
    },

    async edit_row_clicked(item) {

      this.$nextTick(() => {
        this.$emit('select', item);
      });
    },

    async delete_row_clicked(group_id) {

      this.local_group_ids = this.local_group_ids.filter((item) => item !== group_id);

      this.local_group_ids = [ ...this.local_group_ids ];

      this.$emit('selected', this.local_group_ids);
    },

  },

  mounted() {

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }

    if (this.group_ids) {
      this.local_group_ids = [ ...this.group_ids ];
    }

    this.load_items();
  },

  data() {
    return {

      local_group_ids: [],

      groups: [],


      fields: [
        {
          key: 'name',
          label: this.$t('ADVANCED_SEARCH.ATTRIBUTES.GROUP_NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          key: 'action',
          label: '',
          tdClass: 'td-actions',
          thClass: 'td-actions',
          sortable: false
        }
      ],

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
