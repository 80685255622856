<template>
  <tr>
    <td>
      <div class="d-flex align-items-center">
        <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
          <input
            type="checkbox"
            name=""
            v-model="is_active"
            @click="is_active = !is_active"
          />
          <span></span>
        </label>
        <span class="ml-3 cursor-pointer"></span>
      </div>
    </td>


    <td>
      <span class="d-block font-size-lg">{{ term.name }}</span>
    </td>


    <!-- <td>
      <span class="d-block font-size-lg">{{ getTypeString(template.type) }}</span>
    </td> -->
    <td class="text-right pr-0">
      <a
        href="#"
        class="btn btn-icon btn-light btn-sm mx-3"
        @click="selectTermClicked"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Write.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
      <a
        href="#"
        class="btn btn-icon btn-light btn-sm mx-3"
        @click="deleteTermClicked"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
    </td>
  </tr>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'confirm-term-table-row',
  props: ['term'],
  emits: ['selectTermClicked', 'deleteTermClicked', 'isActiveChanged'],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      is_active: false,
    };
  },
  mounted() {
    this.is_active = this.term.is_active;
  },
  watch: {
    'term.is_active'() {
      this.is_active = this.term.is_active;
    },
    is_active() {
      this.$emit('isActiveChanged', this.term.id, this.is_active);
    }
  },
  methods: {
    getTypeString(type) {
      return this.types[type];
    },
    selectTermClicked() {
      this.$emit('selectTermClicked', this.term.id);
    },
    deleteTermClicked() {
      this.$emit('deleteTermClicked', this.term.id);
    },
  }
};
</script>
