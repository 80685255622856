<template>
    <b-card>
        <h4 class="mb-8">{{$t('PAGES.EVENTS.TICKETS')}}</h4>

        <b-modal ref="ticketEditor" size="lg" hide-footer :title="$t('PAGES.EVENTS.EDIT_TICKET')">

          <ShopItemEdit
            v-if="creating || editing"
            class="scroll-to-shopitem"
            ref="shopItemEdit"
            :shop_item_id="shop_item_id"
            :templates="templates"
            :memberTypeOptions="memberTypeOptions"
            @onDeleteShopItem="onDeleteShopItem"
            @onShopItemCreated="onShopItemCreated"
            @onShopItemUpdated="onShopItemUpdated"
            @onCloseClicked="onCloseTicketEditor"
            :is_ticket="true"
          />
        </b-modal>

        <!-- begin: add or create modal -->
        <b-modal ref="addOrCreateModal" size="lg" hide-footer :title="$t('PAGES.EVENTS.CREATE_OR_CHOOSE')">
            <b-row class="mb-8">
              <b-col lg="12">
                <b-button variant="success" block @click.prevent="selectCreateNewShopItem">{{$t('PAGES.EVENTS.NEW_TICKET')}}</b-button>
              </b-col>
            </b-row>

            <b-row>
            <b-col lg="12">
                <b-table
                  id="shop-items-history-table"
                  :fields="historicShopItemTicketHeaders"
                  :items="historicShopItemTickets"
                  head-variant="light"
                  sticky-header
                  class="mh-100"
                >
                    <template #cell(shop_item_id)="row">
                    <div class='justify-content-end d-flex'>

                        <a
                        href="#"
                        class="btn btn-icon btn-light btn-sm mr-3"
                        @click="selectHistoryShopItem(row.item.shop_item_id)"
                        >
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                            <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                        </span>
                        </a>

                    </div>
                    </template>
                </b-table>


            </b-col>
            </b-row>

            <b-row>
            <b-col>
                <b-button class="" variant="outline-secondary" block @click="onCancel">{{$t('COMMON.CANCEL')}}</b-button>
            </b-col>
            </b-row>
        </b-modal>
        <!-- end: add or create modal -->


        <!--begin::Advance Table Widget 9-->
        <div class="card card-custom card-stretch gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
            <!-- <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark">&nbsp;</span>
            </h3> -->
            <div class="card-toolbar d-flex justify-content-between justify-content-sm-end w-100">

                <a href="#" class="btn btn-primary font-weight-bolder font-size" @click.prevent="addEventTicketClicked"
                ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.EVENTS.ADD_TICKET')}}</a>
            </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0 pb-3 table-reponsive">
            <b-table
                id="event-table"
                :fields="eventShopItemHeaders"
                :items="eventShopItems"
                head-variant="light"
                sticky-header
                class="mh-100"
            >
                <template #cell(id)="row">
                <div class='justify-content-end d-flex'>

                    <a
                    href="#"
                    class="btn btn-icon btn-light btn-sm mr-3"
                    @click.prevent="selectEventTicket(row.item.id)"
                    >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                    </span>
                    </a>
                    <a
                    href="#"
                    class="btn btn-icon btn-light btn-sm"
                    @click.prevent="deleteEventTicket(row.item.id, $event)"
                    >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                    </span>
                    </a>
                </div>
                </template>
            </b-table>

            </div>
            <!--end::Body-->
        </div>
        <!--end::Advance Table Widget 9-->
    </b-card>
</template>

<style lang="scss" scoped>
:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 150px;
}

</style>

<script>
import axios from 'axios';
import ShopItemEdit from '@/view/pages/ml/shopitem/ShopItemEdit.vue';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'event-tickets-section',
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'reg_only' ]),
  },
  mixins: [ toasts ],
  props: ['event'],
  emits: ['showHistoryToggled'],
  components: {
    ShopItemEdit
  },
  watch: {

    show_history(newcheck, oldcheck) {
      this.$emit('showHistoryToggled', newcheck);
    }
  },
  mounted() {
    this.loadTemplates();
    this.loadMemberTypes();
    this.loadShopItemTickets();
  },
  methods: {
    setEvent(event) {

      this.eventShopItems = event.shop_items;

      for (let i = 0; i < event.shop_items.length; ++i) {
        this.addUpdateEventShopItem(event.shop_items[i]);
      }
    },
    onCancel() {
      this.$refs['addOrCreateModal'].hide();
    },
    loadMemberTypes() {
      axios
        .get('/membertype')
        .then(res => {
          this.memberTypeOptions = [];

          for (const mt of res.data) {
            this.memberTypeOptions.push({ value: mt.id, text: mt.name });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBERTYPES.UNABLE_LIST_MEMBERTYPES'));
        });
    },
    async loadTemplates() {
      if (!this.currentCompanyId) {
        return;
      }

      this.templates = [];
      this.templateOptions = [];
      axios
        .get(`/template?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.templates = res.data;

            this.templates.forEach(t => {
              this.templateOptions.push({ text: t.name, value: t.template_id });
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SHOPITEM.UNABLE_LIST_SHOPITEMS'));
        });
    },

    onShopItemCreated(data) {
      this.historicShopItemTickets.push(data);

      this.assignShopItemIdToEvent(data.shop_item_id);
    },

    /**
     * event_shop_item = { event_id: 0, shop_item: {}, shop_item_id: 0 }
     *  */
    addUpdateEventShopItem(event_shop_item) {

      const ee = this.eventShopItems.find(item => item.id === event_shop_item.id);

      if (ee) {
        this.eventShopItems = this.eventShopItems.filter(item => item.id !== event_shop_item.id);
      }

      if (event_shop_item.shop_item) {
        event_shop_item.name = event_shop_item.shop_item.name;
        event_shop_item.price_sek = event_shop_item.shop_item.price_sek;
      }

      this.eventShopItems.push(event_shop_item);
    },

    onCloseTicketEditor() {
      this.$refs['ticketEditor'].hide();
    },

    onShopItemUpdated(data) {
      const index = this.eventShopItems.findIndex(item => item.shop_item_id === data.shop_item_id);

      if (index !== -1) {
        const elem = this.eventShopItems[index];
        elem.name = data.name;
        elem.price_sek = data.price_sek;
        elem.shop_item = data;
        this.eventShopItems[index] = elem;
        // Vue3 compatability for Vue.set
        this.eventShopItems = [...this.eventShopItems];
      }
      else {
        console.error('item not found in table');
      }
    },

    onDeleteShopItem(shop_item_id) {
      console.error('delete not implemented');
    },

    assignShopItemIdToEvent(shop_item_id) {
      axios
        .post(`/events/ticket`, { event_id: this.event.event_id, shop_item_id: shop_item_id })
        .then(res => {
          if (res.status !== 201) {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_CONNECT_TICKET'));
            return;
          }

          const d = res.data;

          this.addUpdateEventShopItem(d);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENTS.TICKET_CONNECTED'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_CONNECT_TICKET'));
        });
    },

    selectHistoryShopItem(shop_item_id) {
      this.$refs['addOrCreateModal'].hide();

      // when the user selected an old shop item to be used in the even
      this.assignShopItemIdToEvent(shop_item_id);
    },

    selectCreateNewShopItem() {
      this.$refs['addOrCreateModal'].hide();
      this.creating = true;
      this.editing = true;
      this.shop_item_id = null;

      this.$refs['ticketEditor'].show();

      this.$nextTick(() => {
        this.$refs['shopItemEdit'].createShopItem();
      });

    },

    selectEventTicket(id) {
      const esi = this.eventShopItems.find(item => item.id === id);

      if (!esi) {
        console.error('didnt find event shop item in list');
        return;
      }

      this.shop_item_id = esi.shop_item_id;

      this.editing = true;
      this.$refs['ticketEditor'].show();

      this.$nextTick(() => {
        this.$refs['shopItemEdit'].selectShopItem(this.shop_item_id);

        this.scrollToClassName('scroll-to-shopitem');
      });
    },

    scrollToClassName(classname) {

      this.$nextTick(() => {
        const el = this.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });

    },

    deleteEventTicket(id, event) {
      event.preventDefault();

      axios
        .delete(`/events/ticket/${id}`)
        .then(res => {
          this.eventShopItems = this.eventShopItems.filter(item => item.id !== id);
          this.editing = false;
          this.creating = false;
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENTS.TICKET_DELETED'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_DELETE_TICKET'));
        });
    },

    addEventTicketClicked() {
      // when we want to "add a previous" or create a ticket, we must display a popup which gives the user choice
      this.$refs['addOrCreateModal'].show();
    },


    loadShopItemTickets() {
      axios
        .get(`/shop_item/tickets?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            //this.eventShopItems = res.data;
            this.historicShopItemTickets = res.data;

            return;
          }

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_LIST_TICKETS'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_LIST_TICKETS'));
        });
    }
  },
  data() {
    return {
      shop_item_id: null,
      creating: false,
      editing: false,
      eventShopItems: [],
      eventShopItemHeaders: [
          {
          key: 'name',
          label: this.$t('COMMON.NAME'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'price_sek',
          label: this.$t('COMMON.PRICE_SEK'),
          sortable: true,
          thClass: 'w-140px align-middle',
          tdClass: 'w-140px',
        },
        {
          key: 'id',
          label: this.$t('COMMON.ACTIONS'),
          thClass: 'text-right pr-22 w-110px align-middle',
        },
      ],
      historicShopItemTickets: [],
      historicShopItemTicketHeaders: [
          {
          key: 'name',
          label: this.$t('COMMON.NAME'),
          sortable: true,
          thClass: 'td-overflow',
          tdClass: 'td-overflow'
        },
        {
          key: 'price_sek',
          label: this.$t('COMMON.PRICE_SEK'),
          sortable: true,
          thClass: 'w-140px align-middle',
          tdClass: 'w-140px',
        },
        {
          key: 'shop_item_id',
          label: this.$t('COMMON.SELECT'),
          thClass: 'text-right pr-22 w-110px align-middle',
        },
      ],
      show_history: false,
      templates: [],
      memberTypeOptions: [],
      templateOptions: [],
    };
  }
};
</script>
