<template>
  <div class="mt-8" id="page-event-category-edit">
    <b-card v-if="creating || editing" :title="$t('PAGES.EVENT_CATEGORY.EDIT')" class="mb-2 mt-8 edit-event-category" hide-footer>

        <b-form class="mt-8 ml-0 mr-0 px-0" @submit="saveData">

            <p class="mt-4 mb-4">
              {{$t('PAGES.EVENT_CATEGORY.LINK')}}: <a v-if="form.url" :href="form.url">{{form.url}}</a>
            </p>

            <b-form-group class="mt-8" id="input-group-name" :label="$t('PAGES.EVENT_CATEGORY.NON_PUBLIC_NAME')" label-for="input-name">
            <b-form-input
                id="input-name"
                v-model="form.name"
                type="text"
                required
            ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-header" :label="$t('PAGES.EVENT_CATEGORY.HEADER')" label-for="input-header">
            <b-form-input
                id="input-header"
                v-model="form.header"
                type="text"
                required
            ></b-form-input>
            </b-form-group>


            <b-form-group id="input-group-redirect_url" :label="$t('PAGES.EVENT_CATEGORY.FORWARD_SUCCESS')" label-for="input-redirect_url">
            <b-form-input
                id="input-header"
                v-model="form.redirect_url"
                type="text"
                required
                :state="validUrl"
            >

            </b-form-input>
            <b-form-text>{{$t('PAGES.EVENT_CATEGORY.URL_INFO')}}</b-form-text>
            </b-form-group>

            <b-form-group id="input-group-desc" :label="$t('PAGES.EVENT_CATEGORY.PAGE_TEXT')" label-for="input-desc">
              <tiptap-vuetify
                data-app
                v-model="form.html"
                :extensions="extensions"
              />
              
            </b-form-group>


            <ConfirmTermsTable
              ref="termsTable"
              :isEvent="true"
              :category_id="event_category.id"
            />

            <b-button type="submit" variant="primary">{{$t('COMMON.SAVE')}}</b-button>

        </b-form>

    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TiptapVuetify, Image, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
import ConfirmTermsTable from '@/view/pages/ml/registrationsetup/ConfirmTermsTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'EventCategoryEditor',
  components: {
    TiptapVuetify,
    ConfirmTermsTable
  },
  mixins: [ toasts ],
  props: ['event_category'],
  emits: ['saveData'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    validUrl() {
      const reg = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/

      const test = reg.test(String(this.form.redirect_url).toLowerCase());

      return test;
    }
  },
  mounted() {

  },
  data() {
    return {
      extensions: [
        Image,
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      levelOptions: [
        { value: 0, text: '0 ' + this.$t('PAGES.EVENT_CATEGORY.LOWEST') },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10 ' + this.$t('PAGES.EVENT_CATEGORY.HIGHEST') }
      ],
      form: {
        redirect_url: ''
      },
      editing: false,
      creating: false,
    };
  },
  watch: {

  },
  methods: {
    createNewEventCategory() {
      this.creating = true;
      this.form = {};

      this.scrollToEdit();
    },
    editEventCategory() {
      this.creating = false;
      this.editing = true;

      this.$nextTick(() => {
        this.form = this.event_category;
        this.$refs['termsTable'].loadTerms();
      });

      this.scrollToEdit();
    },
    saveData(e) {
      e.preventDefault();

      this.creating = false;
      this.editing = false;

      this.$emit('saveData', this.form);
    },
    scrollToEdit() {
      this.$nextTick(() => {
        const el = this.$el.getElementsByClassName('edit-event-category')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });
    },

  }
};
</script>
