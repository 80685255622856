<template>
  <div class="px-5" id="page-events">
    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('PAGES.EVENT_CATEGORY.CONFIRM_DELETE')"
      ref="deleteEventCategoryConfirm"
      type="success"
      @confirm="onDeleteEventCategoryConfirmed"
    />
    <div class="scroll-to-top"></div>

    <EventCategoryTable
      :items="event_categories"
      @createEventCategoryClicked="createEventCategoryClicked"
      @selectEventCategoryClicked="selectEventCategoryClicked"
      @deleteEventCategoryClicked="deleteEventCategoryClicked"
      @toastr="toastr"
    />

    <EventCategoryEdit
      ref="eventCategoryEditor"
      :event_category="event_category"
      @saveData="saveEventCategory"
    />

  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import EventCategoryEdit from '@/view/pages/ml/event_category/EventCategoryEdit.vue';
import EventCategoryTable from '@/view/pages/ml/event_category/EventCategoryTable.vue';
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'event-categories-page',
  components: {
    EventCategoryEdit,
    EventCategoryTable,
    Confirm
  },
  emits: ['eventCategoryCreated', 'eventCategoryDeleted'],
  computed: {
    ...mapGetters(['companies', 'currentCompanyId', 'currentPeriodId'])
  },
  mixins: [ toasts ],
  mounted() {
    this.loadData();
  },
  data() {
    return {
      id: null,
      event_categories: [],
      event_category: null
    };
  },
  watch: {
    currentCompanyId() {},
    currentPeriodId() {},
  },
  methods: {


    loadData() {
      axios
        .get(`/event_category`)
        .then(res => {
          if (res.status === 200) {
            this.event_categories = res.data;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENT_CATEGORY.UNABLE_LIST'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENT_CATEGORY.UNABLE_LIST'));
        });
    },

    selectEventCategoryClicked(id) {
      this.getEventCategory(id);
    },

    createEventCategoryClicked() {

      this.postEventCategory({
        descr: '',
        name: 'Ny kategori',
        template: '',
        company_id: this.currentCompanyId,
        period_id: this.currentPeriodId,
        html: '',
        header: '',
        redirect_url: ''
      })
      .then(event_category => {
        this.event_categories.push(event_category);
        this.event_category = event_category;

        this.$nextTick(() => {
          this.$refs['eventCategoryEditor'].editEventCategory();
          this.$emit('eventCategoryCreated', this.event_category);
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENT_CATEGORY.CREATED'));
        });

      })
      .catch(err => {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENT_CATEGORY.UNABLE_CREATE'));
      });
    },

    saveEventCategory(form) {

      form.company_id = this.currentCompanyId;
      form.period_id = this.currentPeriodId;

      if (form.hasOwnProperty('id') && form.id) {
        const id = form.id;
        delete form.id;

        this.putEventCategory(id, form)
        .then(success => {
          form.id = id;
          const index = this.event_categories.findIndex(item => item.id === id);

          this.event_categories[index] = form;
          // Vue3 compatability for Vue.set
          this.event_categories = [...this.event_categories];

          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENT_CATEGORY.UPDATED'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENT_CATEGORY.UNABLE_UPDATE'));
        });
      }

    },

    postEventCategory(data) {

      return new Promise(async (resolve, reject) => {
        axios
          .post(`/event_category`, data)
          .then(res => {
            if (res.status === 201) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });

    },


    putEventCategory(id, data) {
      return new Promise(async (resolve, reject) => {
        axios
          .put(`/event_category/${id}`, data)
          .then(res => {
            if (res.status === 204) {
              resolve(true);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });


    },



    getEventCategory(id) {
      axios
        .get(`/event_category/${id}`)
        .then(res => {
          if (res.status === 200) {
            this.event_category = res.data;
            this.$refs['eventCategoryEditor'].editEventCategory();
            this.$emit('eventCategoryDeleted', id);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENT_CATEGORY.UNABLE_GET'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENT_CATEGORY.UNABLE_GET'));
        });
    },

    deleteEventCategoryClicked(id) {
      this.id = id;
      this.$refs['deleteEventCategoryConfirm'].show();
    },

    onDeleteEventCategoryConfirmed() {
      this.deleteEventCategory(this.id);
    },


    deleteEventCategory(id) {
      axios
        .delete(`/event_category/${id}`)
        .then(res => {
          if (res.status === 204) {
            this.event_categories = this.event_categories.filter(item => item.id !== id);
            this.id = null;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENT_CATEGORY.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENT_CATEGORY.UNABLE_DELETE'));
        });
    },
  }
};
</script>
