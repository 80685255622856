<template>

  <div>
    <form>

      <b-card style="border: 1px solid #dedede;" :title="$t('PAGES.EVENTS.TICKET')">

        
        <b-row>
          <b-col>
            <b-form-group

              id="input-group-ticket_name"
              :label="$t('PAGES.EVENTS.TICKET')" label-for="input-ticket_name">
              <b-form-select
                id="input-ticket_id"
                v-model="local_item.ticket_id"
                :options="ticketOptions"
                type="text"
                :disabled="!!local_item.member_event_id"
            ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="local_item.member_event_id">

            <ButtonWithLoader
              ref="send-ticket-button"
              :text="$t('PAGES.EVENTS.SEND_TICKET')"
              @clicked="send_ticket()"
              class="ml-4"
              style="margin-top: 26px;"
            />
          </b-col>

        </b-row>

        <p style="color:red; font-style: italic;" v-if="local_item.ticket_id && !ticket_not_in_event">
          {{ $t('PAGES.EVENTS.TICKET_NOT_IN_EVENT') }}
        </p>


        <b-row class="mt-4">
          <b-col>
            <b-form-group

              id="input-group-event_id"
              :label="$t('PAGES.EVENTS.EVENT')" label-for="input-event_id">
              <b-form-select
                id="input-event_id"
                v-model="local_item.event_id"
                :options="event_options"
              ></b-form-select>
            </b-form-group>

          </b-col>
        </b-row>

        <b-row class="mt-4" v-if="!local_item.member_event_id">
          <b-col>
              <div class="d-flex align-items-center">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input

                      type="checkbox"
                      name=""
                      v-model="local_item.send_ticket_email"
                      />
                      <span></span>
                  </label>
                  <span @click="local_item.send_ticket_email = !local_item.send_ticket_email" class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.TICKET_SEND')}}</span>
              </div>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <div class="d-flex align-items-center">
              <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                  <input type="checkbox" name="" v-model="checked_in" />
                  <span></span>
              </label>
              <span class="ml-3 cursor-pointer" @click="checked_in = !checked_in"
              >{{$t('PAGES.EVENTS.CHECKED_IN')}}</span>
          </div>
          </b-col>

        </b-row>

        <RightSaveButton
          v-if="!creating"
          ref="save-member-event"
          :text="$t('COMMON.SAVE')"
          @clicked="update_member_event()"
          class="ml-4 mt-4 mx-right"
        />

      </b-card>



      <b-card
        :title="$t('PAGES.EVENTS.PARTICIPANTS')" class="mt-4">
        <b-row class="mt-6">
          <b-col>
            <b-form-group id="input-group-6"
              :label="$t('PAGES.EVENTS.PARTICIPANT_TYPE')" label-for="input-6">
              <div class="radio-list mt-4">
                <label class="radio radio-outline radio-success mb-4">
                  <input type="radio" v-model="participant_type" value="MEMBER" name="participant_type" />
                  <span></span>
                  {{$t('MEMBER.MEMBER')}}
                </label>
                <label class="radio radio-outline radio-success mb-4">
                  <input type="radio" v-model="participant_type" value="PERSON" name="participant_type" />
                  <span></span>
                  {{$t('MEMBER.PERSON')}}
                </label>
              </div>
            </b-form-group>

          </b-col>
        </b-row>

        <b-row class="mt-6" v-if="creating">
          <b-col>
              <div class="d-flex align-items-center">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                      type="checkbox"
                      name=""
                      v-model="create_new_participant"
                      />
                      <span></span>
                  </label>
                  <span @click.prevent="create_new_participant = !create_new_participant" class="ml-3 cursor-pointer">{{$t('COMMON.CREATE_NEW')}} {{ participant_type === 'PERSON' ? $t('MEMBER.PERSON').toLowerCase() : $t('MEMBER.MEMBER').toLowerCase() }}</span>
              </div>
          </b-col>
        </b-row>


        <b-row v-if="!create_new_participant" class="mt-8">
          <b-col>
            <div v-if="participant_type === 'MEMBER'">
              <b-form-group
                id="member_id"
                :label="$t('PAGES.EVENTS.SEARCH_SELECT_MEMBER')"
                label-for="member_id"
                class="mt-8"
                >
                <QuickSearchMemberWidget
                  @member_selected="participantMemberSelected"
                />
              </b-form-group>

              <b-form-group
                class="col-lg-6 col-12"
                v-if="participant"
                id="input-group-member_id"
                :label="$t('COMMON.SELECTED')"
                label-for="input-header">
              <b-form-input
                  id="input-header"
                  v-model="participant.email"
                  type="text"
                  disabled
              ></b-form-input>
              </b-form-group>
            </div>

            <div v-if="participant_type === 'PERSON'">
              <b-form-group
                id="person_id"
                :label="$t('PAGES.EVENTS.SEARCH_SELECT_PERSON')"
                label-for="person_id"
                class="mt-8"
                >
                <QuickSearchPersonWidget
                  @personSelected="participantPersonSelected"
                />
              </b-form-group>

              <b-form-group
                class="col-lg-6 col-12"
                v-if="participant"
                id="input-group-member_id"
                :label="$t('COMMON.SELECTED')"
                label-for="input-header">
              <b-form-input
                  id="input-header"
                  v-model="participant.email"
                  type="text"
                  disabled
              ></b-form-input>
              </b-form-group>
            </div>
          </b-col>
        </b-row>

        <p class="mt-4" v-if="local_item.is_member === true">{{ $t('MEMBER.IS_MEMBER') }}</p>
        <p class="mt-4" v-if="local_item.is_member === false">{{ $t('MEMBER.IS_PERSON') }}</p>

        <MemberEventIndividualEdit
          class="mt-8"
          :is_member="local_item.is_member"
          :item="participant"
          :id="local_item.is_member ? local_item.member_id : local_item.person_id"
          @onCreate="on_participant_create"
          @onUpdate="on_participant_update"
        />

      </b-card>


      <b-card
        :title="$t('MEMBER.INVOICE_INFO')"
        class="mt-4">
        <b-row>
          <b-col>
              <div class="d-flex align-items-center">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input
                      type="checkbox"
                      name=""
                      :disabled="!creating"
                      v-model="local_item.invoice_same_as_participant"
                      />
                      <span></span>
                  </label>
                  <span @click="local_item.invoice_same_as_participant = !local_item.invoice_same_as_participant" class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.SAME_AS_PARTICIPANT')}}</span>
              </div>
          </b-col>
        </b-row>

        <div v-if="!local_item.invoice_same_as_participant && creating">
          <b-row class="mt-6">
            <b-col>
                <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                        <input
                        type="checkbox"
                        name=""
                        v-model="create_new_invoice"
                        />
                        <span></span>
                    </label>
                    <span @click="create_new_invoice = !create_new_invoice" class="ml-3 cursor-pointer">{{$t('COMMON.CREATE_NEW')}} {{ invoice_type === 'PERSON' ? $t('MEMBER.PERSON') : $t('MEMBER.MEMBER') }}</span>
                </div>
            </b-col>
          </b-row>

          <b-row class="mt-6">
            <b-col>
              <b-form-group
                id="input-group-6"
                :label="$t('PAGES.EVENTS.INVOICE_RECIPIANT_TYPE')"
                label-for="input-6">

                <div class="radio-list mt-4">
                  <label class="radio radio-outline radio-success mb-4">
                    <input type="radio" v-model="invoice_type" value="MEMBER" name="invoice_type" />
                    <span></span>
                    {{$t('MEMBER.MEMBER')}}
                  </label>
                  <label class="radio radio-outline radio-success mb-4">
                    <input type="radio" v-model="invoice_type" value="PERSON" name="invoice_type" />
                    <span></span>
                    {{$t('MEMBER.PERSON')}}
                  </label>
                </div>
              </b-form-group>

            </b-col>
          </b-row>


          <b-row v-if="!create_new_invoice">
            <b-col>
              <div v-if="invoice_type === 'MEMBER'">
                <b-form-group
                  id="member_id"
                  :label="$t('PAGES.EVENTS.SEARCH_SELECT_MEMBER')"
                  label-for="member_id"
                  class="mt-8"
                  >
                  <QuickSearchMemberWidget
                    @member_selected="invoiceMemberSelected"
                  />
                </b-form-group>

                <b-form-group
                  class="col-lg-6 col-12"
                  v-if="invoice" id="input-group-member_id"
                  :label="$t('COMMON.SELECTED')"
                  label-for="input-header">
                <b-form-input
                    id="input-header"
                    v-model="invoice.email"
                    type="text"
                    disabled
                ></b-form-input>
                </b-form-group>
              </div>

              <div v-if="invoice_type === 'PERSON'">
                <b-form-group
                  id="person_id"
                  :label="$t('PAGES.EVENTS.SEARCH_SELECT_PERSON')"
                  label-for="person_id"
                  class="mt-8"
                  >
                  <QuickSearchPersonWidget
                    @personSelected="invoicePersonSelected"
                  />
                </b-form-group>

                <b-form-group
                  class="col-lg-6 col-12"
                  v-if="invoice"
                  id="input-group-member_id"
                  :label="$t('COMMON.SELECTED')"
                  label-for="input-header">
                <b-form-input
                    id="input-header"
                    v-model="invoice.email"
                    type="text"
                    disabled
                ></b-form-input>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <MemberEventIndividualEdit
            class="mt-8"
            :is_member="local_item.invoice_is_member"
            :item="invoice"
            :id="local_item.invoice_is_member ? local_item.invoice_member_id : local_item.invoice_person_id"
            @onCreate="onInvoiceCreate"
            @onUpdate="onInvoiceUpdate"
          />

        </div>
      </b-card>

      <p v-if="!creating" class="mt-8" style="color: #888888;">{{$t('PAGES.EVENTS.INFO_PARTICIPATION')}}</p>

      <RightSaveButton
        :disabled="disabledSave"
        ref="save-button"
        :text="$t('COMMON.SAVE')"
        @clicked="submit_clicked()"
        class="mb-4 mx-right"
      />

      <p class="error" v-if="!local_item.member_id && !local_item.person_id">
        {{ $t('PAGES.EVENTS.PARTICIPANT_MISSING') }}
      </p>

      <p class="error" v-if="!local_item.ticket_id">
        {{ $t('PAGES.EVENTS.TICKET_MISSING') }}
      </p>


      <b-alert class="mt-8" v-if="error === 'full'" show variant="danger"
        >{{$t('PAGES.EVENTS.EVENT_FULL')}}</b-alert
      >

      <b-alert class="mt-8" v-if="error === 'request'" show variant="danger"
        >{{$t('PAGES.EVENTS.MISSING_FIELDS')}}</b-alert
      >

      <b-alert class="mt-8" v-if="error === 'undefined'" show variant="danger"
        >{{$t('PAGES.EVENTS.INTERNAL_ERROR')}}</b-alert
      >

    </form>
  </div>

</template>


<style lang="scss" scoped>

.form-group {
  margin-bottom: 6px;
}

.error {
  color: red;
  text-align: right;
}

</style>

<script>

import { validate_email } from '@/core/services/email';
import validate_pers_number from '@/core/services/personnr';
import QuickSearchMemberWidget from '@/view/pages/ml/search/QuickSearchMemberWidget.vue';
import QuickSearchPersonWidget from '@/view/pages/ml/search/QuickSearchPersonWidget.vue';
import MemberEventIndividualEdit from '@/view/pages/ml/events/MemberEventIndividualEdit.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import ButtonWithLoader from '@/view/components/buttons/ButtonWithLoader.vue';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';


export default {
  name: 'member-event-editor',
  props: ['events','item','event'],
  emits: ['created', 'updated', 'change', 'deleteMemberClicked'],
  mixins: [ toasts ],
  components: {
    QuickSearchMemberWidget,
    QuickSearchPersonWidget,
    MemberEventIndividualEdit,
    ButtonWithLoader,
    RightSaveButton
  },
  computed: {
    ticket_not_in_event() {
      if (this.local_item.ticket_id) {
        const ticket = this.ticketOptions.find(item => item.value === this.local_item.ticket_id);

        if (ticket) {
          return true;
        }
      }
      
      return false;
    },
    creating() {
      return !this.local_item.member_event_id;
    },
    ticket_name() {
      if (!this.ticketOptions) {
        return '-';
      }

      console.log('this.local_item.tickets', this.local_item.tickets)

      if (this.local_item && this.local_item.tickets && this.local_item.tickets.length > 0) {
        return this.local_item.tickets[0].shop_item.name;
      }

      return '--';
    },
    event_options() {
      if (!this.events) {
        return [];
      }

      const event_options = this.events.map(event => {
        return {
          value: event.event_id,
          text: event.name
        };
      });

      // sort on name
      event_options.sort((a, b) => {
        if (a.text < b.text) { return -1; }
        if (a.text > b.text) { return 1; }
        return 0;
      });

      return event_options;
    },
    disabledSave() {
      if (!this.local_item.member_id && !this.local_item.person_id) {
        return true;
      }

      if (!this.local_item.ticket_id) {
        return true;
      }

      return false;
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    validName() {
      if (!this.local_item.name) { return null; }

      return this.local_item.name.length > 1;
    },
    validEmail() {
      if (!this.local_item.email) { return null; }

      return validate_email(this.local_item.email);
    },
    validPhone() {
      if (!this.local_item.phone) { return null; }

      return this.validate_phone(this.local_item.phone);
    },
    validPersonnr() {
      if (!this.local_item.personnr) { return null; }

      const formatted = validate_pers_number(this.local_item.personnr);

      return formatted.valid;
    },
    validZipcode() {

      if (!this.local_item.zipcode) { return null; }

      return !!this.local_item.zipcode && !isNaN(parseInt(this.local_item.zipcode)) && this.local_item.zipcode.length > 4;
    },
    validAddress() {
      if (!this.local_item.address) { return null; }

      return !!this.local_item && !!this.local_item.address && this.local_item.address.length > 3;
    },
    validPost() {
      if (!this.local_item.post) { return null; }

      return !!this.local_item && !!this.local_item.post && this.local_item.post.length > 1;
    },
    validAllAddress() {
      const v1 = this.notNullAndTrue(this.validZipcode);
      const v2 = this.notNullAndTrue(this.validAddress);
      const v3 = this.notNullAndTrue(this.validPost);

      return v1 && v2 && v3;
    },

    allIsValid() {
      const v1 = this.notNullAndTrue(this.validEmail);
      const v2 = this.notNullAndTrue(this.validPhone);
      //const v3 = this.notNullAndTrue(this.validPersonnr);
      const v4 = this.notNullAndTrue(this.validAllAddress);

      return v1 && v2 && v4;
    }
  },
  watch: {
    event_id(newValue) {
      this.local_item.event_id = newValue;
      this.loadTickets(this.local_item.event_id);
    },
    participant_type(newValue) {
      this.local_item.is_member = newValue === 'MEMBER';
    },
    invoice_type(newValue) {
      this.local_item.invoice_is_member = newValue === 'MEMBER';
    },
    invoice_same_as_participant(newValue, oldValue) {

    },
    'local_item.zipcode'() {
      this.$emit('change', this.local_item);
    },
    'local_item.address'() {
      this.$emit('change', this.local_item);
    },
    'local_item.phone'() {
      this.$emit('change', this.local_item);
    },
    'local_item.email'() {
      this.$emit('change', this.local_item);
    },
    'local_item.post'() {
      this.$emit('change', this.local_item);
    },
    'local_item.personnr'() {
      this.$emit('change', this.local_item);
    }
  },
  data() {
    return {

      checked_in: false,

      /// Participant data ///
      create_new_participant: false,
      participant: {},
      participant_type: 'PERSON',
      /// --- ///

      /// Invoice individual ///
      create_new_invoice: false,
      invoice: {},
      invoice_type: 'PERSON',
      /// --- ///

      /// Ticket ///
      ticket_id: null,
      /// --- ////

      ticketOptions: [

      ],

      local_item: {
        event_id: null,
        member_id: null,
        person_id: null,
        invoice_person_id: null,
        is_member: false,
        invoice_person_id: null,
        invoice_member_id: null,
        invoice_same_as_participant: true,
        send_ticket_email: false,
        shop_order_id: null
      },

      error: null
    };
  },
  async mounted() {

    this.create_new_invoice = false;

    if (this.item) {
      this.local_item = { ...this.item };
    }

    console.log('local_item', this.local_item)

    if (this.local_item.invoice_same_as_participant === undefined) {
      this.local_item.invoice_same_as_participant = true;
    }

    this.local_item.event_id = this.event.event_id;
    await this.loadTickets(this.event.event_id);

    await this.setup_member_event();
  },
  methods: {
    async submit_clicked() {
      this.local_item.checkedin = this.checked_in;

      if (!this.creating) {
        this.$refs['save-button'].stop();
        return;
      }

      try {

        if (this.local_item.send_ticket_email === undefined) {
          this.local_item.send_ticket_email = false;
        }

        const data = await this.postMemberEvent(this.local_item);

        if (data) {
          this.$emit('created', data);
          this.creating = false;
          this.error = '';

          this.$refs['save-button'].stop();

          return;
        }
      }
      catch (err) {
        if (err === 'full') {
          this.error = 'full';
        }
        else if (err === 'request') {
          this.error = 'request';
        }
        else {
          this.error = 'undefined';
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_ADD_MEMBER_PERSON'));
        }
      }

      this.$refs['save-button'].stop();

    },

    async send_ticket() {
      try {


        const res = await axios.post(`/member_event/admin/send_ticket/${this.local_item.member_event_id}`);

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENTS.SUCCESS_SEND_TICKET'));
          this.$refs['send-ticket-button'].stop();

          return;
        }
      }
      catch (err) {
        console.error('send_ticket error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.ERROR_SEND_TICKET'));

      this.$refs['send-ticket-button'].stop();
    },

    update_member_event() {
      this.local_item.checkedin = this.checked_in;
      this.putMemberEvent(this.local_item)
      .then(_res => {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.EVENTS.UPDATED'));
        this.$emit('updated', this.local_item);

        this.$refs['save-member-event'].stop();
      })
      .catch(err => {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_UPDATE'));

        this.$refs['save-member-event'].stop();
      });
    },

    /// -- update and create the member_event -- ///
    invoicePersonSelected(person) {

      this.local_item.invoice_person_id = person.person_id;
      this.local_item.invoice_person = person;

      this.local_item.invoice_member_id = null;
      this.local_item.invoice_member = null;

      this.local_item.invoice_is_member = false;

      this.invoice = person;

      this.local_item = { ...this.local_item }
    },

    invoiceMemberSelected(member) {

      this.local_item.invoice_member_id = member.member_id;
      this.local_item.invoice_member = member;

      this.local_item.invoice_person = null;
      this.local_item.invoice_person_id = null;

      this.local_item.invoice_is_member = true;

      this.invoice = member;

      this.local_item = { ...this.local_item }
    },

    participantPersonSelected(person) {

      this.local_item.person_id = person.person_id;
      this.local_item.person = person;

      this.local_item.member = null;
      this.local_item.member_id = null;
      this.local_item.is_member = false;

      this.participant = person;

      this.local_item = { ...this.local_item }
    },


    participantMemberSelected(member) {

      console.log('member selected', member);

      this.local_item.member_id = member.member_id;
      this.local_item.member = member;
      this.local_item.person = null;
      this.local_item.person_id = null;
      this.local_item.is_member = true;

      this.participant = member;

      this.local_item = { ...this.local_item }
    },

    onInvoiceCreate(data) {
      data.company_id = this.currentCompanyId;
      data.period_id = this.period_id;

      if (this.invoice_type === 'PERSON') {
        this.postPerson(data)
        .then(person => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PERSON.PERSON_CREATED'));
          this.invoice = person;
          this.local_item.invoice_person_id = person.person_id;
          this.local_item.invoice_member_id = null;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERSON.UNABLE_CREATE_PERSON'));
        });
      }
      else if (this.invoice_type === 'MEMBER') {
        data.in_personnr = data.personnr;

        this.postMember(data)
        .then(member => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.MEMBER_CREATED'));

          this.invoice = member;
          this.invoice.personnr = member.fmt_personnr;
          this.local_item.invoice_member_id = member.member_id;
          this.local_item.invoice_person_id = null;

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_CREATE_MEMBER'));
        });
      }
    },

    onInvoiceUpdate(data) {
      if (this.invoice_type === 'PERSON') {
        this.putPerson(this.local_item.invoice_person_id, data)
        .then(person => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PERSON.PERSON_UPDATED'));
          this.invoice = person;

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERSON.UNABLE_UPDATE_PERSON'));
        });
      }
      else if (this.invoice_type === 'MEMBER') {
        data.in_personnr = data.personnr;

        this.putMember(this.local_item.invoice_member_id, data)
        .then(member => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.MEMBER_UPDATED'));
          this.invoice = member;

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_MEMBER'));
        });
      }
    },

    async on_participant_create(data) {
      data.company_id = this.currentCompanyId;
      data.period_id = this.period_id;

      if (this.participant_type === 'PERSON') {
        try {
          const person = await this.postPerson(data)
        
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PERSON.PERSON_CREATED'));
          this.participant = person;
          this.local_item.person_id = person.person_id;
          this.local_item.member_id = null;
          this.local_item.is_member = false;

          this.participant = { ...this.participant };
          this.local_item = { ...this.local_item };
        }
        catch (err) {
          console.error('on_participant_create error', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERSON.UNABLE_CREATE_PERSON'));
        }
      }
      else if (this.participant_type === 'MEMBER') {
        data.in_personnr = data.personnr;

        const member = await this.postMember(data)
        
        try {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.MEMBER_CREATED'));
          this.participant = member;
          this.participant.personnr = member.fmt_personnr;
          this.local_item.member_id = member.member_id;
          this.local_item.person_id = null;
          this.local_item.is_member = true;

          this.participant = { ...this.participant };
          this.local_item = { ...this.local_item };

        }
        catch (err) {
          console.error('on_participant_create error', err);

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_CREATE_MEMBER'));
        }
      }
    },

    on_participant_update(data) {
      if (this.participant_type === 'PERSON') {
        this.putPerson(data.person_id, data)
        .then(person => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PERSON.PERSON_UPDATED'));
          this.participant = person;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PERSON.UNABLE_UPDATE_PERSON'));
        });
      }
      else if (this.participant_type === 'MEMBER') {
        data.in_personnr = data.personnr;

        this.putMember(data.member_id, data)
        .then(member => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.MEMBER_UPDATED'));
          this.invoice = member;

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_UPDATE_MEMBER'));
        });
      }
    },

    postMember(data) {
      return new Promise(async (resolve, reject) => {
        axios
          .post(`/member`, data)
          .then(res => {
            if (res.status === 201) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    putMember(member_id, data) {
      return new Promise(async (resolve, reject) => {
        axios
          .put(`/member/${member_id}`, data)
          .then(res => {
            if (res.status === 200) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    postPerson(data) {
      return new Promise(async (resolve, reject) => {
        data.source = 'EVENT_ADMIN';
        axios
          .post(`/person`, data)
          .then(res => {
            if (res.status === 201) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    putPerson(person_id, data) {
      return new Promise(async (resolve, reject) => {
        axios
          .put(`/person/${person_id}`, data)
          .then(res => {
            if (res.status === 200) {
              resolve(res.data);
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    setup_member_event() {

      this.participant_type = this.local_item.is_member ? 'MEMBER' : 'PERSON';
      this.invoice_type = this.local_item.invoice_is_member ? 'MEMBER' : 'PERSON';

      this.participant = this.local_item.is_member ? this.local_item.member : this.local_item.person;
      this.invoice = this.local_item.invoice_is_member ? this.local_item.invoice_member : this.local_item.invoice_person;
      this.ticket_id = this.local_item.tickets && this.local_item.tickets.length > 0 ? this.local_item.tickets[0].shop_item.shop_item_id : null;

      this.checked_in = this.local_item.checkedin;

    },

    loadTickets(event_id) {
      axios
        .get(`/events/${event_id}`)
        .then(res => {
          if (res.status === 200) {

            this.ticketOptions = [];

            for (const si of this.event.shop_items) {
              this.ticketOptions.push({ text: si.shop_item.name, value: si.shop_item_id });
            }
          }
          else {
            console.error(`GET /events/${event_id} invalid status ${res.status}`);
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.EVENTS.UNABLE_LIST_TICKETS'));
        });
    },


    createNew() {
      this.creating = true;
      this.participant = {};
      this.invoice = {};
      this.checked_in = false;
    },
    notNullAndTrue(val) {
      if (val === null || val === undefined) { return false; }
      return val;
    },
    validateAll() {
      const v1 = this.notNullAndTrue(this.validEmail);
      const v2 = this.notNullAndTrue(this.validPhone);
      //const v3 = this.notNullAndTrue(this.validPersonnr);
      const v4 = this.notNullAndTrue(this.validAllAddress);

      return v1 && v2 && v4;
    },

    validate_phone(phone) {
      if ((phone+'').length < 6) {
        return false;
      }

      var re = /^\+?\d*$/;
      return re.test(String(phone).toLowerCase());
    },

    delete_row_clicked() {
      this.$emit('deleteMemberClicked', this.item.id);
    },

    putMemberEvent(form) {
      return new Promise(async (resolve, reject) => {
        axios
          .put(`/member_event/${form.member_event_id}`, form)
          .then(res => {
            if (res.status === 204) {
              resolve(null);
            }
            else if (res.status === 400) {
              reject('request');
            }
            else if (res.status === 409) {
              reject('full');
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    postMemberEvent(form) {
      return new Promise(async (resolve, reject) => {
        axios
          .post(`/member_event/admin_signup/${form.event_id}`, form)
          .then(res => {
            if (res.status === 201) {
              resolve(res.data);
            }
            else if (res.status === 400) {
              reject('request');
            }
            else if (res.status === 409) {
              reject('full');
            }
            else {
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },

  }
};
</script>
