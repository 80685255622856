<template>
  <b-modal size="xl" ref="member-editor-compact-modal" hide-footer>
    <MemberEditorCompact
      :member_id="local_member_id"
      @created="member_created"
      @updated="member_updated"
      @deleted="member_deleted"
    />
  </b-modal>
</template>

<style lang="scss" scoped>

:deep .modal-xl {

  @media (max-width: 1024px) {
    max-width: 100% !important;
  }
  @media (min-width: 1025px) {
    max-width: 90% !important;
    padding-right: 17px;
    padding-left: 17px;
  }

  overflow-x: visible;

}
</style>
<script>
import MemberEditorCompact from './MemberEditorCompact.vue';

export default {
  name: 'member-editor-compact-modal',
  props: ['member_id'],
  emits: ['created', 'updated', 'deleted'],
  data() {
    return {
      local_member_id: null,
    };
  },
  components: { MemberEditorCompact },
  watch: {
    member_id(new_value) {
      this.local_member_id = new_value;
    },
  },
  async mounted() {
    if (this.member_id) {
      this.local_member_id = this.member_id;
    }
  },

  methods: {
    member_created(member) {
      this.$emit('created', member);
    },

    member_updated(member) {
      this.$emit('updated', member);
    },

    show() {
      this.$refs['member-editor-compact-modal'].show();
    },

    close() {
      this.$refs['member-editor-compact-modal'].hide();
    },

    member_deleted(member_id) {
      this.close();

      this.$emit('deleted', member_id);
    },

    onCloseMemberEditor() {
      this.close();
    },

  },
};
</script>
