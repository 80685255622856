<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <h4>{{ $t('MEMBER_PROPERTY.SELECT_GROUPS_HEADER') }}</h4>


      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6"
              :label="$t('ADVANCED_SEARCH.ATTRIBUTES.GROUP_NAME')">

              <b-form-select v-model="group_id" :options="group_options"></b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>
            <div class="justify-content-end d-flex">
              <b-button class="mr-2 cancel" variant="danger" @click="cancel_clicked">{{ $t('COMMON.CANCEL') }}</b-button>
              <b-button ref="save" class="save" variant="primary" type="submit">{{ $t('COMMON.SELECT') }}</b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>


    </div>
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';


export default {

  name: 'AttributeGroupSelectEditor',

  props: ['groups'],
  emits: ['cancel', 'selected'],
  mixins: [ toasts ],

  components: {

  },

  watch: {

  },

  computed: {
    ...mapGetters(['currentCompanyId']),
    group_options() {
      return this.groups.map((item) => ({ text: item.name, value: item.group_id }));
    },
  },


  mounted() {

  },

  methods: {

    cancel_clicked() {
      this.$emit('cancel');
    },

    async on_submit() {
      this.$emit('selected', this.group_id);
    },
  },


  data() {
    return {

      group_id: null,

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
