<template>
  <div>
    <b-row>
      <b-col>
        <b-form @submit.prevent="submitSearch($event)">

          <b-input-group>
            <b-form-input
              v-model="text"
              :placeholder="$t('PAGES.QUICKSEARCH.SEARCH_TERMS')"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-success" type="submit" :disabled="disablebutton">{{$t('COMMON.SEARCH')}}</b-button>
            </b-input-group-append>
          </b-input-group>

        </b-form>
      </b-col>
    </b-row>
    <b-row class="mt-6">
      <b-col>
        <b-table
          selectable
          select-mode="single"
          class="table-striped"
          responsive
          :fields="personFields"
          :items="persons"
          @row-selected="on_person_selected"
          >

        </b-table>
      </b-col>
    </b-row>
  </div>
</template>
<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';


import { mapGetters } from 'vuex';
import dayjs from "dayjs";
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'quickSearch-person-widget',
  components: {

  },
  emits: ['personSelected'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mixins: [ toasts ],
  data() {
    return {
      personFields: [
        { key: 'name', label: this.$t('MEMBER.NAME'), thStyle: 'width: 33%' },
        { key: 'person_org_number', label: this.$t('MEMBER.PERS_ORGNR'), thStyle: 'width: 33%' },
        { key: 'address', label: this.$t('MEMBER.ADDRESS'), thStyle: 'width: 33%' },
      ],
      persons: [],
      disablebutton: false,
      text: ''
    };
  },
  async mounted() {

  },
  watch:{

  },
  methods: {
    on_person_selected(rows) {
      this.personSelected(rows[0]);
    },
    submitSearch(e) {
      e.preventDefault();

      this.personSearch(this.text);
    },
    personSelected(person) {
      this.person_id = person.person_id;
      //this.showMemberEditor = true;
      this.$emit('personSelected', person);
      //this.scrollToClassName('form-create-member');
    },
    personSearch(str) {
      const loader = this.$loading.show();
      str = str + '';
      str = str.toUpperCase();
      this.disablebutton = true;
      axios
        .post(
          `/person/concat_search`,
          {
            str: str,
            all: true
          }
        )
        .then(res => {
          this.disablebutton = false;

          if (res.status === 200) {
            const persons = res.data;
            this.persons = persons.map(item => {
              item.name = item.firstname + ' ' + item.lastname;
              item.person_org_number = item.org_number && item.org_number.length > 0 ? item.org_number : item.personnr;
              return item;
            });
          }
          else {
            this.persons = [];
          }


          //this.scrollToClassName('search-results');
          loader.hide();
        })
        .catch(err => {
          this.disablebutton = false;
          loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.QUICKSEARCH.UNABLE_SEARCH_PERSON'));
        });
    },
    scrollToClassName(classname) {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

  }
};
</script>
