<template>
    <div>
      <b-button variant="primary" :disabled="disabled || spinning" @click.prevent="on_click">
        <b-spinner v-if="spinning" small type="grow"></b-spinner>
        {{ text }}
      </b-button>
    </div>
  </template>

  <script>
  import axios from 'axios';
  import dayjs from 'dayjs';

  import { mapGetters } from 'vuex';
  import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

  export default {
    name: 'ButtonWithLoader',
    components: {

    },
    mixins: [ toasts ],
    props: ['text','disabled'],
    emits: ['clicked'],
    computed: {
      ...mapGetters([])
    },
    data() {
      return {
        spinning: false
      };
    },
    watch: {

    },
    mounted() {

    },
    methods: {
      stop() {
        const that = this;

        setTimeout(()=>{
          that.spinning = false;
        }, 500);
      },

      on_click() {
        this.spinning = true;

        this.$emit('clicked');
      }
    }
  };
  </script>

  <style scoped>

  </style>
