<template>
  <div class="no-padding">
    <b-modal ref="select-attributes-modal">
      <AttributeGroupSelectTable
        :group_ids="local_group_ids"
        @selected="selected_group_ids"
      />
    </b-modal>

    <b-button
      @click="open_modal"
      block
      variant="outline-primary">
        {{ $t('MEMBER_PROPERTY.SELECT_GROUPS', { count: local_group_ids.length}) }}</b-button>
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';
import AttributeGroupSelectTable from './AttributeGroupSelectTable.vue';


export default {

  name: 'AttributeGroupSelectButton',

  props: ['group_ids'],
  emits: ['selected'],
  mixins: [ toasts ],

  components: {
    AttributeGroupSelectTable
  },

  watch: {
    group_ids: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        if (this.group_ids) {
          this.local_group_ids = [ ...this.group_ids ];
        }
        else {
          this.local_group_ids = [];
        }
        
      }
    },
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
  },

  mounted() {
    if (this.group_ids) {
      this.local_group_ids = [ ...this.group_ids ];
    }
    else {
      this.local_group_ids = [];
    }
  },

  methods: {
    selected_group_ids(group_ids) {
      this.local_group_ids = group_ids;

      this.$emit('selected', this.local_group_ids);
    },

    open_modal() {
      this.$refs['select-attributes-modal'].show();
    }
  },

  data() {
    return {
      local_group_ids: []
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
