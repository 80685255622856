<template>
  <b-modal size="xl" ref="member-event-editor-modal" hide-footer>
    
    <MemberEventEditor
      :item="item"
      :events="events"
      :event="event"
      @updated="updated_member_event"
      @created="created_member_event"
    />
  </b-modal>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import MemberEventEditor from '@/view/pages/ml/events/MemberEventEditor.vue';

export default {
  name: 'MemberEventEditorModal',
  mixins: [ toasts ],
  components: {
    MemberEventEditor
  },
  props: ['item','event','events'],
  emits: ['updated','created'],
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods'])
  },
  mounted() {

  },
  data() {
    return {
      
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    updated_member_event(member_event) {
      this.$emit('updated', member_event);
    },

    created_member_event(member_event) {
      this.$emit('created', member_event);
    },

    show() {
      this.$refs['member-event-editor-modal'].show();
    }
  }
};
</script>
