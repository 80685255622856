<template>
  <div>
    <b-row>
      <b-col cols="7">
        <b-form-group
          id="add-user-select-group"
          :label="$t('USER.ADD_USER')"
          label-for="add-user-select"
        >
          <b-form-select
            id="add-user-select"
            class="mr-sm-2 mx-0"
            v-model="selectedUserId"
            :options="usersOptions"
          ></b-form-select>
        </b-form-group>

      </b-col>
      <b-col cols="5" class="d-flex justify-content-end align-left align-bottom">
        <a
          class="btn btn-primary font-weight-bolder font-size-sm align-bottom"
          style="max-height: 38px; margin-top: 24px;"
          v-if="selectedUserId"
          @click="addUserToNotif"
        >
          <i class="menu-icon flaticon2-plus pr-0 pr-md-2" style="font-size: 1em"></i
          ><span class="d-none d-md-inline-block">{{$t('COMMON.ADD')}}</span>
        </a>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-table
          id="users-table"
          class="mt-3 cursor mh-100 table-striped"
          :items="usersSelected"
          :fields="showFields"
          head-variant="light"
          sticky-header
        >

          <template #cell(user_id)="data">
            <div class='d-flex justify-content-end'>

              <a
                href="#"
                class="btn btn-icon btn-light btn-sm mr-4"
                @click.prevent="deleteEmail(data.item.user_id)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                </span>
              </a>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

export default {
  name: 'AddUserList',
  components: {

  },
  props: ['notif_user_ids'],
  emits: ['onNotifEmailsSelected'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies'])
  },
  data() {
    return {
      selectedUserId: null,
      usersOptions: [],
      usersSelected: [],
      showFields: [
        { key: 'email', label: this.$t('MEMBER.EMAIL'), show: true, sortable: false },
        { key: 'user_id', label: '', show: true, sortable: false}
      ],
      allUsers: []
    };
  },
  watch: {

  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    addUserToNotif() {
      const user = this.allUsers.find(item => item.user_id === this.selectedUserId);

      const alreadySelected = this.usersSelected.find(item => item.user_id === this.selectedUserId);

      if (alreadySelected) {
        return;
      }

      this.usersSelected.push(user);

      const notif_user_ids = [];

      for (const u of this.usersSelected) {
        notif_user_ids.push(u.user_id);
      }

      this.$emit('onNotifEmailsSelected', notif_user_ids);
    },

    deleteEmail(user_id) {
      this.usersSelected = this.usersSelected.filter(item => item.user_id !== user_id);

      const notif_user_ids = [];

      for (const u of this.usersSelected) {
        notif_user_ids.push(u.user_id);
      }

      this.$emit('onNotifEmailsSelected', notif_user_ids);
    },

    update() {
      this.loadUsers();
    },

    loadUsers() {

      axios
        .get(`/access/users`)
        .then(res => {
          if (res.status !== 200) {
            return;
          }

          this.allUsers = [];
          this.usersOptions = [];

          for (const user of res.data) {

            let text = (user.firstname !== null && user.lastname !== null) ? user.firstname + ' ' + user.lastname + ' ' + user.email
              : user.email;

            text = text.trim();

            this.usersOptions.push({ value: user.user_id, text: text });

            this.allUsers.push(user);
          }

          this.usersOptions.sort(function (a, b) {
            if (a.text.toUpperCase() < b.text.toUpperCase()) {
              return -1;
            }

            return 1;
          });

          this.usersSelected = [];

          if (this.notif_user_ids) {
            for (const user_id of this.notif_user_ids) {
              const found_user = this.allUsers.find(item => item.user_id == user_id);

              this.usersSelected.push(found_user);
            }
          }

        })
        .catch(err => {
          console.error('error getting users ' + err);
        });

    },
  }
};
</script>
<style scoped>
:deep .table > tbody > tr > td {
  vertical-align: middle;
}
#app .container {
  background-color: #293039;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
}
#bar {
  flex: 1;
  background-color: #293039;
  color: white;
  padding: 10px;
  display: flex;
  max-height: 65px;
}
#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}
#bar button {
  min-width: 80px;
  cursor: pointer;
}
</style>
